//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const notLoginList = []
export default {
	name: "orderEntry",
	data() {
		return {
			color: "",
			routeName: '',
			h5MenuHideTop: [],
            selectedKey: ''
		};
	},
	computed: {
		isLogined() {
			return !!this.$store.state.user.userInfo.id;
		},
		notNeedLogined() {
			return notLoginList.includes(this.$route.name);
		},
		networkList() {
			return this.$store.state.coin.networkList;
		},
	},
	watch: {
		$route: {
			handler(val) {
				this.routeName = val.name;
				if (!this.notNeedLogined) { // 需要登录
					this.checkSignature();
				}
				this.color = val.name === 'overview' ? 'var(--primary-color)' : '';
			},
			immediate: true,
		},
	},
	created() {
		if (!this.notNeedLogined) {
			this.checkSignature();
		}
	},
	methods: {
		checkSignature() {
			if (this.isLogined) return;
			const signature = localStorage.getItem(window.location.hostname + '_signatureSelf');
			console.log('[entry] signature:', signature);
			if (!signature) {
				this.$store.commit('setBackRouteName', this.$route.name)
				this.$router.push({
					name: 'login_wallet'
				})
			}
		},
		fetchNetwork() {
			this.$store.dispatch('fetchNetworkList');
		}
	}
};
