import http from "@cms/utils/http";

function getHost() {
    // return 'http://127.0.0.1:7001';
    const host = window.site_config["payment_api_path"];
    return host;
}


export const fetchSign = (data = {}) => {
    const host = getHost();
    return http({
        url: `${host}/v1.0/user/fiat/${data.channel_code}/sign`,
        method: "post",
        data,
        headers: {
            timeout: 2000,
            chain_id: data.chain_id,
            layer: "L1",
        }
    })
}
