//
//
//
//
//
//
//
//
//

import { fetchSign } from "../../api/payment";
import Storage from "@/utils/localStorage/Storage";

export default {
    name: "Purchase",
    data() {
        return {
            userInfo: null,
            step: 1,
            stepText: {
                1: this.$t("payment_fiat_common_loading"),
                2: this.$t("payment_fiat_common_jumping"),
                6: this.$t("payment_fiat_common_invalid_channel_id"),
                7: this.$t("payment_fiat_common_load_configuration_failure"),
            },
            channel_id: null,
            chain_id: 56,
            network: "BSC",
            crypto: "",
            channel_code: "ach",
            fiat: "USD",
        };
    },
    computed: {
        noteText() {
            return this.stepText[this.step];
        },
    },
    created() {
        const userInfo = Storage.get("USERINFO");
        this.userInfo = userInfo;

        if (this.userInfo && this.userInfo.uid) {
            const { channel_id, chain_id, network, crypto, channel_code } = this.$route.query;
            if (channel_id) {
                this.channel_id = channel_id;
            } else {
                this.step = 6;
                return;
            }
            if (chain_id) {
                this.chain_id = chain_id;
            }
            if (network) {
                this.network = network;
            }
            if (crypto) {
                this.crypto = crypto;
            }
            if (channel_code) {
                this.channel_code = channel_code;
            }

            this.getFiatConfig();
        } else {
            this.$router.push({
                name: "login_wallet",
            });
            this.$message.warning("Please connect your wallet first.");
        }
    },
    methods: {
        async getFiatConfig() {
            this.step = 1;

            const address = this.userInfo.uid;
            const paymentHost = process.env.VUE_APP_MODE === "production" ? window.site_config["payment_api_path"] : "http://pay-api.powx.io:8081"
            const cbUrl = `${paymentHost}/v1.0/user/fiat/${this.channel_code}/callback`;
            console.log("callback_url:", cbUrl);
            const redirectUrl = location.origin;

            await fetchSign({
                chain_id: this.chain_id,

                channel_code: this.channel_code,
                // 配置id
                channel_id: this.channel_id,
                // 钱包地址
                address,
                // 用户id,
                member_id: this.userInfo.id,
                // 接收回调的地址
                callback_url: cbUrl,
                crypto: this.crypto,
                network: this.network,
                redirectUrl,

            })
                .then((res) => {
                    // console.log("res:", res);
                    this.step = 2;

                    const { signature, app_id, pay_id, param_string } = res.data;


                    // 先使用test，后面正式上线要处理 @TODO
                    const url = `https://ramp${
                        process.env.VUE_APP_MODE === "production" ? "" : "test"
                    }.alchemypay.org/?${param_string}`;
                    // const url = `https://ramp${
                    //     process.env.VUE_APP_MODE === "production" ? "" : "test"
                    // }.alchemypay.org/?address=${address}&appId=${app_id}&network=${this.network}&crypto=${this.crypto}&merchantOrderNo=${pay_id}&callbackUrl=${cbUrl}&redirectUrl=${redirectUrl}&merchant_order_no`;
                    console.log("url:", JSON.stringify(url));
                    location.href = url;
                    // window.open(url);
                })
                .catch((e) => {
                    this.step = 7;
                });
        },
    },
};
